import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AuthContext from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './components/Home';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Review from './components/Review';


class App extends Component {
    static contextType = AuthContext;

    // constructor(props) {
    //     super(props);
    // }

    render() {
        // console.log(this.context)
        const { isAuthenticated, isVerifying, isLoggingIn, isLoggingOut } = this.context;

        if(isVerifying){
            return (
                <div className="container">
                    <div className="px-5 text-center">
                        <div className="loading"></div>
                    </div>
                </div>
            )
        }

        if(isLoggingIn){
            return (
                <div className="container">
                    <div className="px-5 text-center">
                        <div className="loading mb-3"></div>
                        <div>Logging In ...</div>
                    </div>
                </div>
            )
        }

        if(isLoggingOut){
            return (
                <div className="container">
                    <div className="px-5 text-center">
                        <div className="loading mb-3"></div>
                        <div>Logging Out ...</div>
                    </div>
                </div>
            )
        }

        return (

            <Router>
                
                <Switch>

                    <ProtectedRoute
                        // exact
                        path="/dashboard"
                        component={Dashboard}
                        isAuthenticated={isAuthenticated}
                        isVerifying={isVerifying}
                    />
                    
                    <Route exact path="/" component={ Home } />

                    <Route exact path="/review/:id" component={ Review } />
                    
                    <Route path="/login" component={ Login } />

                </Switch>
            </Router>
        );
    }

}


export default App;