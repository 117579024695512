import React, { useEffect, useRef } from 'react';
import { ReactSVG } from 'react-svg';
import Lottie from 'react-lottie';
import ReactRotatingText from "react-rotating-text";

import './Hero.scss';
import lottieStars from  './lottie/2445-5-stars.json';
import blueBubble from './assets/bubble-blue.svg';
import greenBubble from './assets/bubble-green.svg';

const Hero = () => {

	const parentNode = useRef(null);
	const canvasNode = useRef(null);

	useEffect(() => {
		
		window.addEventListener("resize", handleScale);

		handleScale();

		return () => {
			window.removeEventListener("resize", handleScale);
		}

	}, []);


	const handleScale = () => {
		const parentWidth = parentNode.current.offsetWidth - 30; // 30 for padding
		const canvasWidth = canvasNode.current.offsetWidth;
		const canvasHeight = canvasNode.current.offsetHeight;
		const scale = ((parentWidth * 100) / canvasWidth);
		const height = (canvasHeight * scale) / 100;

		parentNode.current.style.height = `${height}px`;
		canvasNode.current.style.transform = `scale(${scale / 100})`;
	}

	const tagLines = ["Happy Customers", "Content Clients", "100% User Satisfaction", "Value for Money"];

	return (
		<div className="section hero-section pt-3">
			<div className="container">
				<div className="row">
					<div className="col-12 offset-lg-2 col-lg-8" ref={parentNode}>
						<div className="hero-animation" ref={canvasNode}>
							
							<div className="browser-wrap">
								<div className="browser-mockup">
									<div className="content">
										<div className="tagline">
											<ReactRotatingText
											      items={tagLines}
											      emptyPause={250}
											      pause={2000}
											      typingInterval={70}
											    />
										</div>
										<div className="text-line"></div>
										<div className="text-line right"></div>
										<div className="color-bubbles blue"><ReactSVG src={ blueBubble } /></div>
										<div className="color-bubbles green"><ReactSVG src={ greenBubble } /></div>
									</div>
								</div> 	
							</div>

							<div className="person one">
								<div className="speech sb8">
									<Lottie options={starsDefaults} />
								</div>
							</div>

							<div className="person two">
								<div className="bubbles heart"></div>
								<div className="bubbles heart"></div>
								<div className="bubbles like"></div>
								<div className="bubbles like"></div>
							</div>
							<div className="person three">
								<div className="speech sb7">
									<Lottie options={starsDefaults} />
								</div>
							</div>

						</div>
					</div>
				</div>		
			</div>
		</div>				
	)
}


export default Hero;



const starsDefaults = {
	loop: true,
	autoplay: true, 
	animationData: lottieStars,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice'
	}
}