/*
	Required:
		https://github.com/useAnimations/react-useanimations 
*/

import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import UseAnimations from 'react-useanimations';

import './Footer.scss';
import Logo from '../../svg/screenplify_logo.svg';


const Footer = () => {

	const [primary] = useState(window.config.footer.primary);
	const [secondary] = useState(window.config.footer.secondary);

	return (
		<footer className="bg-dark py-5">
			<div className="container">

				<div className="row">
					
					<div className="col-12 col-md-3">
						<a href="//www.screenplify.com" className="py-0 w-50 d-inline-block footer-logo">
							<ReactSVG src={ Logo } />
						</a>
						<p className="py-2 text-light ls-8 ftw-300">Screenplify is a complete, feature-packed software for digital signage and interactive touchscreens and kiosks. Buy software license for your digital displays or avail our bundle solutions, complete with the latest hardware technologies.</p>
					</div>

					<div className="col-12 col-md-3 pl-md-5">
						{
							primary.map((item, index) => (
								<a key={ index } href={ item.link } target={ item.target } className="btn btn-link text-center text-md-left">{ item.text }</a>
							))
						}
					</div>

					<div className="col-12 col-md-3 pl-md-5">
						{
							secondary.map((item, index) => (
								<a key={ index } href={ item.link } target={ item.target } rel="noopener noreferrer" className="btn btn-link text-center text-md-left">{ item.text }</a>
							))
						}
					</div>

					<div className="col-12 col-md-3">
						<div className="social-icons text-center text-md-right">
							<a href="https://www.facebook.com/screenplify" target="_blank" rel="noopener noreferrer">
								<UseAnimations animationKey="facebook" size={24}  />
							</a>
							<a href="https://www.instagram.com/screenplify" target="_blank" rel="noopener noreferrer">
								<UseAnimations animationKey="instagram" size={24}  />
							</a>
							<a href="https://twitter.com/ounchtag" target="_blank" rel="noopener noreferrer">
								<UseAnimations animationKey="twitter" size={24}  />
							</a>
						</div>
					</div>

				</div>

				<hr/>

				<p className="copy m-0 text-center text-uppercase ls-8 ftw-600">
				    <span>Copyright 2014-2020 ©</span>
				    <span>Omniphics Sdn Bhd</span>
				    <span> • Screenplify</span>
				</p>


			</div>
		</footer>
	)
}

export default Footer;