import React from 'react';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';

// For the Custom li element
import { Link } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Octicon, { iconsByName } from '@primer/octicons-react'


export const SortableItem = sortableElement(({ item, onRemove, onCopy }) =>{ 
	
	const copyURL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')? '//localhost:3000' : 'https://reviews.screenplify.com';

	return (
		<li className="list-unstyled">
			<div className="card border-0 shadow-sm mb-3" >
				<div className="card-body">

					<div className="float-right">

						<CopyToClipboard text={`${copyURL}/review/${item.id}`}
							onCopy={() => onCopy() }
						>
							<button className="btn btn-link text-muted" >
								<Octicon icon={iconsByName['clippy']}/>
							</button>
						</CopyToClipboard>


						<button 
							className="btn btn-link text-danger" 
							onClick={ () => { onRemove(item) } }
						>
							<Octicon icon={iconsByName['trashcan']}/>
						</button>
					</div>

					<Link className="text-reset d-block " to={`/dashboard/edit/${item.id}`}>{item.fullName}</Link>
					<small className="text-muted ls-8 mr-3">{ item.position }, { item.company }</small>
					<small className={ 'ls-8  mr-3 ' + (item.isPublished? 'text-success' : 'text-warning') }>{ item.isPublished ? 'Published' : 'Pending' }</small>
					<small className={ 'ls-8  mr-3 ' + (item.isLocked? 'text-success' : 'text-warning') }>{ item.isLocked ? 'Locked' : 'Open' }</small>
				</div>
			</div>
		</li>

	)
});


export const SortableContainer = sortableContainer(({ children }) => {
	return <ul className="p-0 m-0 list-unstyled" >
		    	{children}
		    </ul>;
});