import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { FirebaseDB } from '../firebase';
import { SwalToast } from './Helpers';
import { StarRater } from './Raters';
import Switch from './MaterialSwitch';
import { MultiChoice } from './BsComponents'

class ReviewForm extends Component {
	
	constructor(props) {
		super(props);
		
		this.fdbListRef = null;

		this.state = {
			fullName: '',
			displayName: '',
			position: '',
			company: '',
			avatar: '',
			stars: 0,
			testimony: '',
			referral: 'definitely',
			isLocked: false,
			isPublished: false,
			isLoading: true,
		}

		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		const { mode } = this.props;

		if(mode === 'ADD')
			this.setState({ isLoading: false });

		if(mode === 'EDIT')
			this.getReview();
	}

	componentWillUnmount(){
		// Detach Firebase Listners
		if(this.fdbListRef !== null) this.fdbListRef.off();
	}


	handleClick(){
		const { uid, history, mode } = this.props;
		const form = document.getElementById('input-form');
		const item = {...this.state};
		let error = false;

		// Remove Loading
		delete item['isLoading'];

		// Reset Form
		form.classList.remove('was-validated');

		// Validation
		error = (item.fullName.trim() === '')? true : error;
		error = (item.displayName.trim() === '')? true : error;
		error = (item.company.trim() === '')? true : error;
		// error = (item.position.trim() === '')? true : error;
		
		// Display Error
		if(error){
			form.classList.add('was-validated');
			SwalToast('Please fix errors', 'error');
			return false;	
		}
		

		// If No Error

		if(mode === 'ADD'){
			FirebaseDB
				.ref('/reviews')
				.push({
					...item,
					created: new Date().getTime(),
					createdBy: uid,
					updated: new Date().getTime(),
					updatedBy: '',
					order: 0
				});
		}

		if(mode === 'EDIT'){
			const { id } = this.props.match.params;

			FirebaseDB
				.ref(`/reviews/${id}`)
				.update({
					...item,
					updated: new Date().getTime(),
					updatedBy: uid
				});
		}
		

		// Redirect to Push
		history.push("/dashboard");
	}


	getReview(){
		const { id } = this.props.match.params;
		let propKeys = { ...this.state }

		// Remove Loading
		delete propKeys['isLoading'];
	
		if(id){

			this.fdbListRef = FirebaseDB
				.ref(`/reviews/${id}`);

			this.fdbListRef.on("value", (snapshot) => {
				
				if(snapshot.val()  !== null){
					const _snap = snapshot.val();

					for(var prop in propKeys){
						propKeys[prop] = (prop in _snap)? _snap[prop] : propKeys[prop];
					}

					this.setState({
						...propKeys,
						isLoading: false
					});
				}

			}, (errorObject) => {
				console.log("The read failed: " + errorObject.code);
			});	
		}
	}


	render() {
		const { isLoading } = this.state;
		const { mode } = this.props;


		if(isLoading){
			return (
				<div className="container pt-5">
					<div className="text-center">
						Loading Info ...
					</div>
				</div>
			)
		}

		return (
			<div className="container pt-5">
				
				<form id="input-form" noValidate>

					<div className="section card border-0 shadow">
						<div className="card-body">
							
							<div className="form-group">
								<label><small className="text-muted d-block ftw-600 ls-8">Full Name</small></label>
								<input type="text" className="form-control" name="fullname" required value={ this.state.fullName } onChange={ (e) => { this.setState({ fullName: e.target.value }) } }/>
								<div className="invalid-feedback">Please provide a Full Name</div>
							</div>	

							<div className="form-group">
								<label><small className="text-muted d-block ftw-600 ls-8">Display Name</small></label>
								<input type="text" className="form-control" name="display" required value={ this.state.displayName } onChange={ (e) => { this.setState({ displayName: e.target.value }) } }/>
								<div className="invalid-feedback">Please provide a Display Name</div>
							</div>	

							<div className="form-group">
								<label><small className="text-muted d-block ftw-600 ls-8">Position</small></label>
								<input type="text" className="form-control" name="position" required value={ this.state.position } onChange={ (e) => { this.setState({ position: e.target.value }) } }/>
								<div className="invalid-feedback">Please provide a Position</div>
							</div>		

							<div className="form-group">
								<label><small className="text-muted d-block ftw-600 ls-8">Company</small></label>
								<input type="text" className="form-control" name="company" required value={ this.state.company } onChange={ (e) => { this.setState({ company: e.target.value }) } }/>
								<div className="invalid-feedback">Please provide a Company</div>
							</div>	

							<div className="form-group">
								<label><small className="text-muted d-block ftw-600 ls-8">How would you rate your experience with Screenplify?</small></label>
								<div className="d-block m-0 p-0">
									<StarRater total={ 5 } rating={ parseInt(this.state.stars) } onRate={ (e) => { this.setState({ stars: e.rating }) } } />
								</div>
							</div>

							<div className="form-group">
								<label><small className="text-muted d-block ftw-600 ls-8">How likely are you to recommend Screenplify to a friend or colleague?</small></label>
								<div className="d-block m-0 p-0">
									<MultiChoice 
										name="referral"
										choice={ this.state.referral } 
										onChange={ (e) => { this.setState({ referral: e.choice }) } } 
									/>
								</div>
							</div>

							<div className="form-group">
								<label><small className="text-muted d-block ftw-600 ls-8">Share a few words on your Screenplify experiance</small></label>
								<textarea 
									name="testimony" 
									className="form-control" 
									rows="3" 
									style={{ resize: 'none' }}  
									value={ this.state.testimony }
									onChange={ (e) => { this.setState({ testimony: e.target.value }) } } 
								>
								</textarea>
							</div>

							<hr/>

							<div className="form-group">
								<label className="mr-2"><small className="text-muted d-block ftw-600 ls-8">Lock Review</small></label>
								<Switch 
									name="is-locked"
									type="success" 
									checked={ this.state.isLocked } 
									onChange={ (checked) => { this.setState({ isLocked: checked }) } } 
									className="sm-switch"
								/>
							</div>

							<div className="form-group">
								<label className="mr-2"><small className="text-muted d-block ftw-600 ls-8">Published</small></label>
								<Switch 
									name="is-published"
									type="success" 
									checked={ this.state.isPublished } 
									onChange={ (checked) => { this.setState({ isPublished: checked }) } } 
									className="sm-switch"
								/>
							</div>

						</div>

						<div className="card-footer">
							<Link to="/dashboard" className="btn btn-link">Cancel</Link>
							{ mode === 'ADD' && <button type="button" className="btn btn-success float-right" onClick={ this.handleClick } >Submit</button>}
							{ mode === 'EDIT' && <button type="button" className="btn btn-primary float-right" onClick={ this.handleClick } >Save Changes</button>}
						</div>				
					</div>

				</form>	

			</div>
		);
	}
}


export default  ReviewForm;