import React, { Component } from 'react';
import Lottie from 'react-lottie';

import './index.scss';
import { FirebaseDB } from '../../firebase';
import Navbar from '../Home/Navbar.js';
import { StarRater } from '../Raters';
import { MultiChoice } from '../BsComponents';
import lottieCheck from '../Home/lottie/433-checked-done.json'



const thanksDefaults = {
	loop: false,
	autoplay: true, 
	animationData: lottieCheck,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice'
	}
}



class Review extends Component {
	constructor(props) {
		super(props);

		// this.fdbListRef = null;
		
		this.state = {
			fullName: '',
			displayName: '',
			position: '',
			company: '',
			avatar: '',
			stars: 0,
			testimony: '',
			referral: 'definitely',
			isLocked: false,
			isPublished: false,
			isLoading: true,
		}

		this.handleClick = this.handleClick.bind(this);
	}


	handleClick(){
		const item = {...this.state};
		const { id } = this.props.match.params;

		// Remove Loading
		delete item['isLoading'];

		// Update
		item.isLocked = true;

		console.log(item);
		


		FirebaseDB
			.ref(`/reviews/${id}`)
			.update({
				...item,
				updated: new Date().getTime(),
				updatedBy: item.fullName
			});

		this.setState({isLocked: true});
	}


	componentDidMount() {
		document.documentElement.classList.add('Review');

		this.getReview();
	}
 

	componentWillUnmount(){
		// Detach Firebase Listners
		// if(this.fdbListRef !== null) this.fdbListRef.off();
		
		document.documentElement.classList.remove('Review');
	}



	getReview(){
		const { id } = this.props.match.params;
		let propKeys = { ...this.state }

		// Remove Loading
		delete propKeys['isLoading'];
	
		if(id){

			FirebaseDB
				.ref(`/reviews/${id}`)
				.once('value')
				.then( snapshot => {
					if(snapshot.val()  !== null){
						const _snap = snapshot.val();

						for(var prop in propKeys){
							propKeys[prop] = _snap[prop];
						}

						this.setState({
							...propKeys,
							isLoading: false
						});
					}					
				}, errorObject => {
					console.log("The read failed: " + errorObject.code);
				})

			/*this.fdbListRef.on("value", (snapshot) => {
				

			}, (errorObject) => {
			})*/;	
		}
	}


	render() {

		const { isLoading, isLocked } = this.state;
		
		if(isLoading){
			return (
                <div className="container">
                    <div className="px-5 text-center">
                        <div className="loading"></div>
                        <div className="ls-8 text-muted">Getting Info ...</div>
                    </div>
                </div>
			)
		}

		if(isLocked){
			return (
				<div className="container pt-5">
					<div className="row">
						<div className="col-12 col-md-6 offset-md-3">
							<div className="text-center">
								<div className="card border-0 shadow">
									<div className="card-body text-center">

										<div className="mx-auto" style={{width: 200, height: 150}}>
											<Lottie options={thanksDefaults} />
										</div>

										<div className="feedback-form pb-4">
											<div className="title ls-8 mb-1">Great!.</div>
											<div className="description ls-8">Your review has been submitted. Thank you.</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}

		return (

			<React.Fragment>

				<Navbar />

				<div className="container feedback-form py-5">

					<div className="row">
						<div className="col-12 col-sm-12 col-md-6 offset-md-3">
							
							<form id="input-form" noValidate>

								<div className="card border-0 shadow">
									<div className="card-body">
										
										<div className="mb-3">
											<div className="title ls-8 mb-1">Your Feedback is Highly Appreciated.</div>
											<div className="description ls-8">Your valued comments, reviews and testimonials help to drive us forward.</div>
										</div>
										

										<div className="form-group">
											<label>Name</label>
											<input type="text" className="form-control" name="display" required value={ this.state.displayName } onChange={ (e) => { this.setState({ displayName: e.target.value }) } }/>
											<div className="invalid-feedback">Please provide your name</div>
										</div>

										<div className="form-group">
											<label>Position</label>
											<input type="text" className="form-control" name="position" required value={ this.state.position } onChange={ (e) => { this.setState({ position: e.target.value }) } }/>
											<div className="invalid-feedback">Please provide your position</div>
										</div>

										<div className="form-group">
											<label>Company</label>
											<input type="text" className="form-control" name="company" required value={ this.state.company } onChange={ (e) => { this.setState({ company: e.target.value }) } }/>
											<div className="invalid-feedback">Please provide your company</div>
										</div>

										<div className="form-group">
											<label className="mb-1">How would you rate your experience with Screenplify?</label>
											<div className="d-block m-0 p-0">
												<StarRater total={ 5 } rating={ parseInt(this.state.stars) } onRate={ (e) => { this.setState({ stars: e.rating }) } } />
											</div>
										</div>

										<div className="form-group">
											<label className="mb-1">How likely are you to recommend Screenplify to a friend or colleague?</label>
											<div className="d-block m-0 p-0">
												<MultiChoice 
													name="referral"
													choice={ this.state.referral } 
													onChange={ (e) => { this.setState({ referral: e.choice }) } } 
												/>
											</div>
										</div>


										<div className="form-group">
											<label>Share a few words on your Screenplify experience</label>
											<textarea 
												name="testimony" 
												className="form-control" 
												rows="3" 
												style={{ resize: 'none' }}  
												value={ this.state.testimony }
												onChange={ (e) => { this.setState({ testimony: e.target.value }) } } 
											>
											</textarea>
										</div>

									</div>

									<div className="card-footer">
										<button type="button" className="btn btn-success float-right" onClick={ this.handleClick } >Submit Review</button>
									</div>	

								</div>
								
							</form>

						</div>
					</div>
						
						
				</div>
				
			</React.Fragment>
		);
	}

}

export default Review;