// https://github.com/NdYAG/react-rater
// https://github.com/NdYAG/react-rater/tree/master/example
// https://rawgit.com/ndyag/react-rater/master/example/index.html


import React from 'react';
import PropTypes from 'prop-types';
import Rater from 'react-rater';

import './index.scss';

export const StarRater = (props) => {
	return (
		<div>
			<Rater { ...props }>
				<Star />
			</Rater>
		</div>
	)
}




const Star = ({ willBeActive, isActive, style }) => {
	const fill = isActive ? '#fc6' : willBeActive ? '#ffdd99' : '#e3e3e3'
	
	return (
		<svg viewBox="0 0 19.481 19.481" width="24" height="24" style={style}>
			<path
				fill={fill}
				d="m10.201,.758l2.478,5.865 6.344,.545c0.44,0.038 0.619,0.587 0.285,0.876l-4.812,4.169 1.442,6.202c0.1,0.431-0.367,0.77-0.745,0.541l-5.452-3.288-5.452,3.288c-0.379,0.228-0.845-0.111-0.745-0.541l1.442-6.202-4.813-4.17c-0.334-0.289-0.156-0.838 0.285-0.876l6.344-.545 2.478-5.864c0.172-0.408 0.749-0.408 0.921,0z"
			/>
		</svg>
	)
}

Star.propTypes = {
	isActive: PropTypes.bool,
	willBeActive: PropTypes.bool,
	style: PropTypes.any
}