import React, { Component } from 'react';

import AuthContext from './AuthContext';
import { FirebaseApp, FirebaseDB } from '../firebase';

// Wrapper Class
class Auth extends Component {

	state = {
        isLoggingIn: false,
        isLoggingOut: false,
        isVerifying: true,
        loginError: false,
        logoutError: false,
        isAuthenticated: false,
        user: {}
	};

	fdbListRef = null;
	
	componentDidMount(){

		/*setTimeout(() => {
			this.setState({ isAuthenticated: true });
		}, 5000);	*/	

		FirebaseApp
			.auth()
			.onAuthStateChanged((user) => {
				if (user) {
					this.setState({
						user,
						isVerifying: false,
						isAuthenticated: true,
						isLoggingIn: false,
						isLoggingOut: false,
						loginError: false
					});
				} else {
					this.setState({
						user: {},
						isVerifying: false,
						isAuthenticated: false,
						isLoggingIn: false,
						isLoggingOut: false,
						loginError: false
					});
				}
			});
	}


	componentWillUnmount(){
		// Detach Firebase Listners
		if(this.fdbListRef !== null) this.fdbListRef.off();
	}


	login = (email, password) => {
		this.setState({isLoggingIn: true});

		FirebaseApp
			.auth()
			.signInWithEmailAndPassword(email, password)
			.catch((error) => {
				console.log(error)
				this.setState({ loginError: true });
			});
	}

	signout = () => {
		console.log('Logout here');
		this.setState({isLoggingOut: true});
		
		FirebaseApp
			.auth()
			.signOut()
			.then(function() {
				// Sign-out successful.
			}).catch(function(error) {
				// An error happened.
			});
	}

	
	updateUser = (data) => {
		FirebaseApp
			.auth()
			.currentUser
			.updateProfile(data)
			.then(function() {
				// Update successful.
			}).catch(function(error) {
		  		// An error happened.
			});
	}


	getReviews = (filter) => {
		filter = (typeof filter === undefined)? 'ALL' : filter;

		return new Promise((resolve, reject) => {

			if(filter === 'ALL'){
				this.fdbListRef = FirebaseDB
					.ref('/reviews');
			}

			
			if(filter === 'PUBLISHED'){
				this.fdbListRef = FirebaseDB
					.ref('/reviews')
					.orderByChild('isPublished')
					.equalTo(true);
			}	

			this.fdbListRef.on("value", (snapshot) => {
					let reviews = [];

					if(snapshot.val() !== null){
						const _snap = snapshot.val();
						const _keys = Object.keys(_snap)
						
						reviews = _keys.map((key) => {
							return { id: key, ..._snap[key] };
						});
						
						// Order By Order Property
						reviews.sort((a, b) => (a.order > b.order) ? 1 : -1)
					}


					resolve(reviews);
				
				}, (errorObject) => {
					reject(errorObject);
				});

		});
	}


	render() {
		return (
			<AuthContext.Provider 
				value = {{
					...this.state,
					login: this.login,
					signout: this.signout,
					updateUser: this.updateUser,
					getReviews: this.getReviews
				}}
			>
				{ this.props.children }
			</AuthContext.Provider>
		) 
	}

}

export default Auth;