import React from 'react'
import PropTypes from 'prop-types';

import './index.scss';

export const MultiChoice = ({ name, choice, onChange, choices }) => {
	return (
		<div className="multichoice">
			{choices.map((option, index) => {
				const optionLower = option.toLowerCase();
				const optionId = optionLower + index;

				return (
					<div className="custom-control custom-radio custom-control-inline" key={ index }>
						<input 
							type="radio" 
							id={ optionId }  
							name={ name } 
							value={ optionLower } 
							checked={ choice === optionLower } 
							onChange={ () => onChange({ choice: optionLower }) }
							className="custom-control-input"
						/>
						<label className="custom-control-label" htmlFor={ optionId } >{ option }</label>
					</div>
				)
			})}
		</div>
	)
}

MultiChoice.defaultProps = {
    name: 'referral',
    choice: '',
    choices: ['Definitely', 'Probably', 'Maybe', 'Probably Not', 'Definitely not']
}

MultiChoice.propTypes = {
	name: PropTypes.string,
	choice: PropTypes.string,
	choices: PropTypes.array,
	onChange: PropTypes.func
}