import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import './index.scss';

import AuthContext from '../../context/AuthContext';
import Navbar from '../Navbar';
import ReviewsList from '../ReviewsList';
import ReviewForm from '../ReviewForm';

class Dashboard extends Component {
    static contextType = AuthContext;

	// constructor(props) {
	// 	super(props);
	// }

	componentDidMount(){
		document.body.classList.add('Dashboard');
	}

	componentWillUnmount() {
		document.body.classList.remove('Dashboard');	
	}
	
	render() {
		return (
			<div>

				<Navbar onSignOut= { this.context.signout } />

                <Switch>

                    <Route path='/dashboard' exact component={ ReviewsList } />
                    <Route path='/dashboard/reviews' component={ ReviewsList } />

					<Route
						path='/dashboard/add'
						render={(props) => <ReviewForm {...props} uid={ this.context.user.uid } mode="ADD" />}
					/>

					<Route
						path='/dashboard/edit/:id'
						render={(props) => <ReviewForm {...props} uid={ this.context.user.uid } mode="EDIT" />}
					/>


                </Switch>

			</div>
		);
	}
}

export default  Dashboard;