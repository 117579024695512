import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';

import './Navbar.scss';
import Logo from '../../svg/screenplify-logo.svg';

export default function Navbar() {
	const [state, setState] = useState('');

	useEffect(() => {

		window.addEventListener('scroll', handleScroll);

		handleScroll();

		return () => {
			window.removeEventListener('scroll', handleScroll);
		}

	}, []);

	const handleScroll = () =>{
		(!window.requestAnimationFrame) ? fixMainNav() : window.requestAnimationFrame(fixMainNav);
	}

	const fixMainNav = () => {

	    var offsetTop = 90,
	        scrollTop = window.scrollY + 75;   

	    if( scrollTop >= offsetTop ){
	        setState('nav-shrink'); 
	    } else {
	        setState('');
	    }
	}

	return (
		<nav id="home-nav" className={ `navbar navbar-light fixed-top ${state}`} >
			<div className="container">
				<a className="navbar-brand ml-auto mr-auto" href="//www.screenplify.com">
					<ReactSVG src={ Logo } className="svg-icon sm" />
				</a>
				{/*<ul className="navbar-nav ml-auto">
					<li className="nav-item active">
						<a className="nav-link" href="//www.screenplify.com">Home <span className="sr-only">(current)</span></a>
					</li>
				</ul>*/}
			</div>
		</nav>
	)
}