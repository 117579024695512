import React, { Component } from 'react';

import './index.scss';
import AuthContext from '../../context/AuthContext';

import Navbar from './Navbar';
import Hero from './Hero';
import Wreaths from '../Awards/Wreath';
import { StarRater } from '../Raters';
import Footer from './../Footer';


class Home extends Component {
	static contextType = AuthContext;

	constructor(props) {
		super(props);

		this.fdbListRef = null;

		this.state = {
			isLoading: true,
			reviews: []
		}
	}

	componentDidMount(){
		this.getReviews();	
		document.documentElement.classList.add('Home');
	}

	componentWillUnmount(){
		// Detach Firebase Listners
		if(this.fdbListRef !== null) this.fdbListRef.off();
		document.documentElement.classList.remove('Home');
	}

	getReviews(){
		// Moved to Auth Context
		this.context.getReviews('PUBLISHED')
			.then((resp) => {

				this.setState({ 
					isLoading: false,
					reviews: resp 
				});

			}, (error) => {
				console.log(error)
			});
	}

	render() {
		const { isLoading, reviews } = this.state;

		return (
			<React.Fragment>
				<Navbar />
				
				<Hero />

				<section className="section py-5">
					<div className="container">

						<div className="row pt-5">
							
							<div className="col-12 col-sm-6 col-md-3 mb-3">
								<Wreaths primary="All Rounder" secondary="5 Stars" />
							</div>

							<div className="col-12 col-sm-6 col-md-3 mb-3">
								<Wreaths primary="Support" secondary="Awesome" />
							</div>

							<div className="col-12 col-sm-6 col-md-3 mb-3">
								<Wreaths primary="User-Friendly" secondary="Best Tech" />
							</div>

							<div className="col-12 col-sm-6 col-md-3 mb-3">
								<Wreaths primary="Customizability" secondary="Best Tech" />
							</div>

						</div>
					</div>
				</section>		

				<section className="section py-5">
					<div className="container">
						
						<div className="section-header text-center">
							<small>Testimonials</small>
							<h2>Don’t just take our word for it</h2>
							<hr />
							<div className="section-para ftw-400">Listen to what our customers and clients have to say about us. <br/>Their valued comments, reviews and testimonials help to drive us forward.</div>
						</div>
						
						{
						 isLoading? 
							<div className="text-center">
								<div className="loading my-3"></div>
							</div> 
							:
							<div className="card-columns testimonial-columns py-5" >
								{ 
									reviews.map((item, index) => (
										<div key={index} className="card testimonial">
											<div className="card-body">
												<StarRater total={ 5 } rating={ parseInt(item.stars) } />
												<p className="testimony">"{ item.testimony }"</p>
												<div className="info">
													<div className="name">{ item.displayName }</div>
													<small className="desc">{ item.position === '' ? '' : <span>{ item.position },</span> } { item.company }</small>
												</div>
											</div>
										</div>	
									))
								}
							</div>
						}

					</div>
				</section>


				<Footer />	

			</React.Fragment>
    	);
  	}
}


export default Home;