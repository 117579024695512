import React, { useState } from 'react';
import './index.scss';



const Swtich = (props) => {
	const type = ('type' in props)? props.type : 'success';
	const name = ('name' in props)? props.name : `cb-${Math.floor(50*Math.random())}-${new Date().getTime()}`;
	const className = ('className' in props)? props.className : '';
	const isChecked = ('checked' in props)? props.checked : false;
	const [checked, setChecked] = useState(isChecked);

	const onChange = (e) => {
		setChecked(e.target.checked);
		if (typeof props.onChange === "function") props.onChange(e.target.checked);
	}

	return (
		<div className={ `material-switch  d-inline-block ${className}` }>
			<input id={ name } name={ name } type="checkbox" className="" checked={ checked } onChange={ onChange } />
			<label htmlFor={ name } className={ `badge-${type}` }> </label>
		</div>
	)

}


export default Swtich;