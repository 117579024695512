import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
// import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAhT9EhqMwApYNJMzvXOhgVJcFUmGEbozw",
  authDomain: "feedback-bf7df.firebaseapp.com",
  databaseURL: "https://feedback-bf7df.firebaseio.com",
  projectId: "feedback-bf7df",
  storageBucket: "feedback-bf7df.appspot.com",
  messagingSenderId: "403687057579",
  appId: "1:403687057579:web:8920155413ef728775afee",
  measurementId: "G-2MV5C20D22"
};

export const FirebaseApp = firebase.initializeApp(firebaseConfig);
export const FirebaseDB = FirebaseApp.database();