import React, { Component } from 'react';
import arrayMove from 'array-move';

import AuthContext from '../context/AuthContext';
import { FirebaseDB } from '../firebase';
import { SortableItem, SortableContainer } from './SortableHOC';
import { SwalConfirm, SwalToast } from './Helpers';


class ReviewsList extends Component {
	static contextType = AuthContext;

	constructor(props) {
		super(props);

		this.fdbListRef = null;

		this.state = {
			isLoading: true,
			reviews: []
		}

		this.onSortEnd = this.onSortEnd.bind(this);
		this.removeReview = this.removeReview.bind(this);
	}

	componentDidMount(){
		this.getReviews();	
	}


	componentWillUnmount(){
		// Detach Firebase Listners
		if(this.fdbListRef !== null) this.fdbListRef.off();
	}


	getReviews(){
		// Moved to Auth Context
		this.context.getReviews('ALL')
			.then((resp) => {

				this.setState({ 
					isLoading: false,
					reviews: resp 
				});

			}, (error) => {
				console.log(error)
			});
	}


	onSortEnd({oldIndex, newIndex}){
		let reviews = this.state.reviews;
		let updateObj = {}

		// Sort
		reviews = arrayMove(reviews, oldIndex, newIndex);


		for (let i = 0; i < reviews.length; i++) {
			let item = reviews[i];

			updateObj[item.id] = {
				...item,
				order: i
			}


			if((i + 1) === reviews.length){

				FirebaseDB
					.ref('/reviews')
					.update(updateObj);

				this.setState({ reviews: reviews });	
			}
		}
	}




	removeReview(item, index){
		SwalConfirm()
			.then(() => {
				FirebaseDB
					.ref(`/reviews/${item.id}`)
					.set(null);
			
				let items = this.state.reviews;
					items.splice(index, 1);

				this.setState({ reviews: items });	
			}, () => { });
	}



	render() {
		const { isLoading, reviews } = this.state;


		if(isLoading){
			return (
				<div className="container">
					<div className="text-center">
						Loading Reviews ...
					</div>
				</div>
			)
		}

		return (
			<div className="container pt-5">

				<SortableContainer onSortEnd={ this.onSortEnd } pressDelay={ 200 } lockAxis="y" helperClass="shadow-lg">
					{reviews.map((item, index) => (
						<SortableItem 
							key={`item-${item.id}`} 
							index={index} 
							item={item} 
							// onEdit={ (item) => { this.editList(item) } }
							onRemove={ (item) => { this.removeReview(item, index) } }
							onCopy={ () => SwalToast('Copied!', '') } 
						/>
					))}
				</SortableContainer>

			</div>
		);
	}
}


export default  ReviewsList;